/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Column from "../components/column"
import Container from "../components/container"
import Page from "../components/page"
import Row from "../components/row"

const PrivacyPolicyPage = ({ data }) => {
  return (
    <Page data={data.contentfulPage}>
      <Container customStyle={{ py: 4 }}>
        <Row>
          <Column>
            {documentToReactComponents(data.contentfulSection.richText.json)}
          </Column>
        </Row>
      </Container>
    </Page>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query privacyPolicyPageQuery {
    contentfulPage(slug: { eq: "privacy-policy" }) {
      ...PageData
    }

    contentfulSection(slug: { eq: "page-section-privacy-policy" }) {
      id
      slug
      richText {
        id
        json
      }
    }
  }
`
